<div class="login">
    <form (ngSubmit)="submittedForm()" #loginForm="ngForm" class="directionContent">
        <div class="form text-center">
            <h1>{{translate.data.login.login}}</h1>
                <div class="col-12">
                        <input type="text"
                        class="phone"
                        placeholder="123 125 235"
                        name="email"
                        ngModel
                        required
                        ng2TelInput
                        [ng2TelInputOptions]="{initialCountry: 'sa'}"
                        (hasError)="hasError($event)"
                        (ng2TelOutput)="getNumber($event)"
                        (intlTelInputObject)="telInputObject($event)"
                        (countryChange)="onCountryChange($event)" />
                </div>
                <div class="col-12">
                    <div class="form-floating">
                        <input type="password"
                                class="form-control directionContent"
                                id="floatingPassword"
                                placeholder="Password"
                                name="password"
                                ngModel
                                required>
                        <label for="floatingPassword" class="rigthAbs">{{translate.data.login.password}}</label>
                    </div>
                </div>
        </div>
        <div class="col-12">
            <div class="join-with">
                <span (click)="handelForgetPassword()" [ngClass]="{'spanEn' : !translate.is_arabic}">{{translate.data.login.forget}}</span>
                <ul class="list-unstyled directionContent" [ngClass]="{'ulEn': !translate.is_arabic}">
                    <li style="margin-top: 15px;">
                        {{translate.data.login.joinWith}}
                    </li>
                    <li (click)="loginSocial('facebook')">
                        <img src="../../../assets/Content/tw/Group 12.png"/>
                    </li>
                    <!-- <li>
                        <img src="../../../assets/Content/fb/Group 11.png"/>
                    </li> -->
                    <li (click)="loginSocial('google')">
                        <img src="../../../assets/google/Group 10227.png"/>
                    </li>
                </ul>
            </div>
        </div>
    <div class="col-12">
        <div class="actions-btn directionLeft">
            <button class="loginBtn" type="submit" [disabled]="loginForm.invalid">{{translate.data.login.loginBtn}}</button>
            <button class="newMember" (click)="handelNewMember()">{{translate.data.login.newMemberBtn}}</button>
        </div>
    </div>
</form>

</div>
