<div class="froget">
    <h1>{{translate.data.login.forget}}</h1>
    <form (ngSubmit)="onSubmitted()" #forgetForm="ngForm">
        <div class="col-12">
            <div class="form-floating mb-3 directionContent">
                <div>
                    <input type="text"
                    class="phone"
                    placeholder="(+22) 99 99 99 99"
                    name="email"
                    ngModel
                    required
                    ng2TelInput
                    [ng2TelInputOptions]="{initialCountry: 'in'}"
                    (hasError)="hasError($event)"
                    (ng2TelOutput)="getNumber($event)"
                    (intlTelInputObject)="telInputObject($event)"
                    (countryChange)="onCountryChange($event)" />
                </div>
                <!-- <input type="phone"
                        class="form-control directionContent"
                        id="floatingInput"
                        placeholder="name@example.com"
                        name="email"
                        ngModel
                        required> -->
            </div>
        </div>
        <button type="submit" [ngClass]="{'btnEn': !translate.is_arabic}" [disabled]="forgetForm.invalid">{{translate.data.login.send}}</button>
    </form>
</div>