import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { AuthenticationComponent } from './authentication.component';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { LogoAuthComponent } from './logo-auth/logo-auth.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { CodeVerficationComponent } from './code-verfication/code-verfication.component';
import { RegisterComponent } from './register/register.component';
import {DropdownModule} from 'primeng/dropdown';
import { ChangePasswordComponent } from './change-password/change-password.component';
// import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {Ng2TelInputModule} from 'ng2-tel-input';



@NgModule({
  declarations: [AuthenticationComponent,
     LoginComponent,
     LogoAuthComponent,
     ForgetPasswordComponent,
     CodeVerficationComponent,
     RegisterComponent,
     ChangePasswordComponent
    ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    DropdownModule,
    Ng2TelInputModule,
    SharedModule,
  ],
  providers: [
  ]
})
export class AuthenticationModule { }
