<div class="login directionContent">
    <form (ngSubmit)="submittedForm()" #registerForm="ngForm" class="directionContent">
        <div class="form text-center">
            <h1>{{translate.data.HomePage.register}}</h1>
            <div class="col-12 relativeC">
                <img src="../../../assets/Path 6.png" class="img" />
                <select class="form-select mb-3"
                    aria-label="Default select example"
                    (change)="handelChangeCountry($event)">
                    <option selected>{{translate.is_arabic ? 'الدوله' : 'Country'}}</option>
                    <option *ngFor="let country of countries" [value]="country.id">
                        {{ country[translate.data.requestdData.genName] }}
                    </option>
                </select>
            </div>
            <div class="col-12 relativeC">
                <img src="../../../assets/Path 6.png" class="img"/>
                <select class="form-select mb-3"
                 aria-label="Default select example" (change)="handelChangeCity($event)">
                 <option selected>{{translate.is_arabic ? 'المدينه' : 'City'}}</option>
                    <option *ngFor="let cyte of cities" [value]="cyte.id">
                        {{cyte[translate.data.requestdData.genName]}}
                    </option>
                  </select>
            </div>
            <div class="col-12">
                <div class="form-floating mb-3">
                    <input type="text"
                            class="form-control directionContent"
                            id="floatingInput"
                            placeholder="إسم المستخدم"
                            name="name"
                            [ngModel]="socialUser?.name"
                            ngModel
                            #name="ngModel"
                            required>
                    <label for="floatingInput" class="rigthAbs">{{translate.data.HomePage.name}}</label>
                    <p *ngIf="(name.dirty || name.touched) && name.errors" class="errorMessage">{{translate.data.formValidate.requiredName}}</p>
                </div>
            </div>
            <div class="col-12">
                <div class="form-floating mb-3">
                    <input type="email"
                            class="form-control directionContent"
                            id="floatingInput"
                            placeholder="الايميل"
                            name="email"
                            [ngModel]="socialUser?.email"
                            email="true"
                            ngModel
                            #email="ngModel"
                            required>
                    <label for="floatingInput" class="rigthAbs">{{translate.data.HomePage.email}}</label>
                    <p *ngIf="(email.dirty || email.touched) && email.errors" class="errorMessage">{{translate.data.formValidate.requiredEmail}}</p>
                </div>
            </div>
            <div class="col-12">
                <div class="form-floating mb-3">
                    <input type="text"
                    class="phone"
                    placeholder="+2 114 525 253 12"
                    name="phoneNumber"
                    ngModel
                    #phoneNumber="ngModel"
                    required
                    ng2TelInput
                    [ng2TelInputOptions]="{initialCountry: 'sa'}"
                    (hasError)="hasError($event)"
                    (ng2TelOutput)="getNumber($event)"
                    (intlTelInputObject)="telInputObject($event)"
                    (countryChange)="onCountryChange($event)" />
                    <!-- <label for="floatingInput" class="rigthAbs">{{translate.data.login.phone}}</label> -->
                    <p *ngIf="(phoneNumber.touched || phoneNumber.dirty) && phoneNumber.errors" class="errorMessage">{{translate.data.formValidate.requiredPhone}}</p>
                </div>
            </div>
            <div class="col-12">
                <div class="form-floating mb-3">
                    <input type="text"
                            class="form-control directionContent"
                            id="floatingInput"
                            placeholder="Address"
                            name="address"
                            ngModel
                            #address="ngModel"
                            required>
                    <label for="floatingInput" class="rigthAbs">{{translate.data.login.address}}</label>
                    <p *ngIf="(address.touched || address.dirty) && address.errors" class="errorMessage">{{translate.data.formValidate.address}}</p>
                </div>
            </div>
            <div class="col-12">
                <div class="form-floating mb-3">
                    <input type="password"
                            class="form-control directionContent"
                            id="floatingPassword"
                            placeholder="Password"
                            name="password"
                            ngModel
                            #password="ngModel"
                            required>
                    <label for="floatingPassword" class="rigthAbs">{{translate.data.login.password}}</label>
                    <p *ngIf="(password.touched || password.dirty) && password.errors" class="errorMessage">{{translate.data.formValidate.requiredPassword}}</p>
                </div>
            </div>
            <div class="col-12">
                <div class="form-floating mb-3">
                    <input type="password"
                            class="form-control directionContent"
                            id="floatingPassword"
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            ngModel
                            #confirmPassword="ngModel"
                            required>
                    <label for="floatingPassword" class="rigthAbs">{{translate.data.login.confirmPassword}}</label>
                    <p *ngIf="(confirmPassword.touched || confirmPassword.dirty) && confirmPassword.errors" class="errorMessage">
                        {{translate.data.formValidate.requiredPassword}}
                    </p>
                </div>
            </div>

        </div>
        <!-- <div class="col-12">
            <div class="join-with">
                <ul class="list-unstyled directionContent">
                    <li style="margin-top: 15px;">
                        {{translate.data.login.joinWith}}
                    </li> -->
                  <!--   <li (click)="loginSocial('facebook')">
                        <img src="../../../assets/Content/tw/Group 12.png"/>
                    </li> -->
                   <!--  <li>
                        <img src="../../../assets/Content/fb/Group 11.png"/>
                    </li> -->
                  <!--   <li (click)="loginSocial('google')">
                        <img src="../../../assets/google/Group 10227.png"/>
                    </li>
                </ul>
            </div>
        </div> -->
        <div class="col-12">
            <div class="actions-btn directionLeft">
                <button class="loginBtn" [ngClass]="{'btnEn': !translate.is_arabic}" type="submit" [disabled]="registerForm.invalid || !checkCodePhone">{{translate.data.HomePage.regiest}}</button>
            </div>
        </div>
    </form>
</div>
