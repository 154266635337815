<div class="login">
    <form (submit)="handelSubmitted()" #changePasswordForm="ngForm" class="directionContent form">
        <h1>{{translate.data.HomePage.changePassword}}</h1>
        <div class="row">
            <div class="col-12">
                <div class="form-floating">
                    <input type="password"
                           class="form-control"
                           id="floatingPassword"
                           name="password"
                           ngModel
                           #password="ngModel"
                           placeholder="Password" required>
                    <label for="floatingPassword">{{translate.is_arabic ? 'كلمه المرور الجديده' : 'new password'}}</label>
                </div>
                <div *ngIf="(password.touched || password.dirty) && password.errors" class="errorMessage">
                    {{translate.data.formValidate.requiredPassword}}
                </div>
            </div>
            <div class="col-12">
                <div class="form-floating">
                    <input type="password"
                           class="form-control"
                           id="floatingPassword"
                           placeholder="Password"
                           name="confirmPasword"
                           ngModel
                           #confirmPasword="ngModel" required>
                    <label for="floatingPassword"> {{translate.is_arabic ? 'كلمه المرور الجديده' : 'new password'}} </label>
                </div>
                <div *ngIf="(confirmPasword.touched || confirmPasword.dirty) && confirmPasword.errors" class="errorMessage">
                    {{translate.data.formValidate.requiredPassword}}
                </div>
            </div>
            <div class="col-12">
                <button class="loginBtn" type="submit" [disabled]="!changePasswordForm.valid">حفظ</button>
            </div>
        </div>
    </form>
</div>
