        <!--Right Side Body [Form]-->
    <div class="col-lg-12 ">
        <form (ngSubmit)="submittedForm()" #verifyForm="ngForm" class="myForm">
            <h3 class="formHeader">{{translate.is_arabic ? 'كود التفعيل' : 'verify code'}}</h3>
            <!--Code Verifiy Input Field-->
            <div class="form-row">
                <input type="text"
                    class="form-control"
                    id="num1"
                    maxlength="1"
                    (keypress)="keyPress($event)"
                    ngModel name="num1" #num1="ngModel" required>

                <input type="text"
                    class="form-control"
                    id="num2"
                    maxlength="1"
                    (keypress)="keyPress($event)"
                    ngModel name="num2" #num1="ngModel" required>

                <input type="text"
                    class="form-control"
                     id="num3"
                     maxlength="1"
                     (keypress)="keyPress($event)"
                    ngModel name="num3" #num1="ngModel" required>

                <input type="text"
                    class="form-control"
                    id="num4"
                    maxlength="1"
                    (keypress)="keyPress($event)"
                    ngModel name="num4" #num1="ngModel" required>

                <!-- <input type="number" class="form-control" min="0" id="num5"> -->
            </div>

            <button class="btn submitButton" [disabled]="verifyForm.invalid" type="submit">{{translate.is_arabic ? 'تحقق' : 'verify'}}</button>

          </form>
    </div>

