import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationComponent } from './authentication.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CodeVerficationComponent } from './code-verfication/code-verfication.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';

const routes: Routes = [
  {path: '', component: AuthenticationComponent, children: [
    {path: '', component: LoginComponent},
    {path: 'forget', component: ForgetPasswordComponent},
    {path: 'new-member', component: RegisterComponent},
    {path: 'verfication-code/:userId/:pathType', component: CodeVerficationComponent},
    {path: 'change-password/:verifyCode/:userId', component: ChangePasswordComponent}
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
