import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { TranslationService } from 'src/app/shared/translation.service';
import { AuthenticationService } from '../Services/authentication.service';
import { User } from '../user.model';
import { SocialAuthService, GoogleLoginProvider, FacebookLoginProvider, SocialUser } from 'angularx-social-login';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('loginForm', {static: true}) loginForm: NgForm;
  numberPhone: string;
  invalidPhone: boolean;
  socialUser: SocialUser;



  constructor(public translate: TranslationService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private sharedService: SharedService,
    private socialAuthService: SocialAuthService,
    private route: ActivatedRoute) { }


  ngOnInit(): void {

    //subscribe auth social data
    this.socialAuthService.authState.subscribe((user: any) => {
      console.log("enter this.socialAuthService.authState");

      if(user) {
        this.socialUser = user;
        // this.loginForm.value.email = this.socialUser.email;
        this.loginForm.value.email = "string";
        this.loginForm.value.socialId = this.socialUser.id;
        delete this.loginForm.value.password;
        console.log('login form data => ', this.loginForm);
        this.login();
      }
    });

  }



  //handel forget password
  handelForgetPassword() {
    this.router.navigate(['./forget'], {relativeTo: this.route});
  }

  //handel new member
  handelNewMember() {
    this.router.navigate(['./new-member'], {relativeTo: this.route});
  }

  //has error
  hasError(e: any) {
    console.log(e);
    this.invalidPhone = e;
  }

  //on country change
  onCountryChange(e: any) {
    console.log(e);
  }

  //getNumber($event)
  getNumber(event: any) {
    console.log(event);
    this.numberPhone = event;
    console.log(this.numberPhone);
  }

  //telInputObject
  telInputObject(e: any) {
    console.log(e);
  }


  //submitted from
  submittedForm() {
    //login form

    if(!this.invalidPhone) {
      this.sharedService.stringErrorMessage.next("رقم الهاتف الذي ادخلته غير صحيح تأكد من الرقم وأنه مطابق للكود");
      this.sharedService.errorShowMessage.next(true);
      this.sharedService.hideErrorMessage();
    } else {
      this.loginForm.value.email = this.numberPhone;
      this.login();
    }

    //this.router.navigate(['./pages'])
  }


  //login function
  login() {
    console.log("this.loginForm.value",this.loginForm.value);
    // let loginData = {...this.loginForm.value,
    //   socialId: "string"}
    this.authenticationService.login(this.loginForm.value).subscribe(
      (response: any) => {
        console.log(response.data);

        let user: User = {
          token: response.data?.token,
          fullName: response.data?.fullName,
          phone: response.data?.phoneNumber,
          email: response.data?.email,
          image: response.data?.address,
          userId: response.data?.userId
        };
        console.log("user",user);

        this.sharedService.useData = user;
        this.authenticationService.setUser(user);
        this.router.navigate(['./pages/home'])

      },
      (error) => {
        console.log("error",error);
        if(error.error) {
          if(this.socialUser?.id) {
            this.router.navigate(['./new-member/'], {relativeTo: this.route, queryParams: {data: JSON.stringify(this.socialUser)}});
          } else {
            this.sharedService.stringErrorMessage.next(error.error.error);
            this.sharedService.errorShowMessage.next(true);
            this.sharedService.hideErrorMessage();
          }
        } else {
          this.sharedService.stringErrorMessage.next("server error, try later !");
          this.sharedService.errorShowMessage.next(true);
          this.sharedService.hideErrorMessage();
        }
      }
    )
  }

  //login social
  loginSocial(type: string) {
    switch (type) {
      case 'google' :
        console.log("GoogleLoginProvider.PROVIDER_ID" ,GoogleLoginProvider.PROVIDER_ID);

        this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
      break;

      case 'facebook' :
        console.log("FacebookLoginProvider.PROVIDER_ID" ,FacebookLoginProvider.PROVIDER_ID);
        this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
        break;
    }
  }






}
