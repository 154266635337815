import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo-auth',
  templateUrl: './logo-auth.component.html',
  styleUrls: ['./logo-auth.component.scss']
})
export class LogoAuthComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
