import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { TranslationService } from 'src/app/shared/translation.service';
import { AuthenticationService } from '../Services/authentication.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  @ViewChild('changePasswordForm', {static:true}) changePasswordForm: NgForm;
  userId: number;
  verifyCode:any;

  constructor(public translate: TranslationService,
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private router: Router,
     private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params: Params) => {
        this.verifyCode = params['verifyCode'];
        this.userId = params['userId'];
      }
    );
    console.log(this.verifyCode);
  }


  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  handelSubmitted() {
    const formData = {
      code: this.verifyCode,
      userId: this.userId,
      password: this.changePasswordForm.value.password,
      confirmPassword: this.changePasswordForm.value.confirmPasword,
    };


    this.authService.changePassword(formData).subscribe(
      (response: any) => {
        console.log(response);
        this.router.navigate(['../../../'], {relativeTo: this.route});
      }
    );

    console.log(formData);
  }

}
