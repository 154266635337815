import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';
import { AuthenticationService } from '../Services/authentication.service';
import { User } from '../user.model';
import { TranslationService } from 'src/app/shared/translation.service';


@Component({
  selector: 'app-code-verfication',
  templateUrl: './code-verfication.component.html',
  styleUrls: ['./code-verfication.component.scss']
})
export class CodeVerficationComponent implements OnInit {


  constructor(private router : Router,
    private authServcie: AuthenticationService,
    public translate: TranslationService,
    private sharedService: SharedService,
    private route: ActivatedRoute) { }

  @ViewChild('verifyForm', {static: true}) verifyForm: NgForm;

  verifyData: any;
  userId: string;
  pathType: string;

  ngOnInit(): void {
    this.route.params.subscribe(
      (params: Params) => {
        this.userId = params['userId'];
        this.pathType = params['pathType'];
        console.log(params['userId']);
      }
    )
    console.log('user data => ', this.userId);
  }

  //revese string
  reverseString(str: string) {
    return str.split("").reverse().join("");
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  submittedForm(){
    let userVerifyCode: string = '';
    for(let num in this.verifyForm.value) {
      console.log(this.verifyForm.value[num]);
      userVerifyCode += this.verifyForm.value[num];
    };

    //data post object
    this.verifyData = {
      userId: this.userId,
      code: this.reverseString(userVerifyCode)
    };

    console.log(this.reverseString(userVerifyCode));
    console.log(this.verifyData);

    if(this.pathType == 'forgetPass') {
      this.router.navigate(['../../change-password/' + this.verifyData.code + '/' + this.verifyData.userId], {relativeTo: this.route});
    } else if(this.pathType == 'register') {
      this.router.navigate(['../../../'], {relativeTo: this.route});
    } else {
      console.log('no data !');
    }
    console.log("this.verifyData",this.verifyData);

   this.authServcie.verficationCode(this.verifyData).subscribe(

      (response) => {
        console.log(response);
        if(this.pathType == 'forgetPass') {
          this.router.navigate(['../../change-password/' + this.verifyData.code + '/' + this.verifyData.userId], {relativeTo: this.route});
        } else {
          this.router.navigate(['../../../'], {relativeTo: this.route});
        }
      },
      (error) => {
        console.log(error);
        if(error.error) {
          this.sharedService.stringErrorMessage.next(error.error.error);
          this.sharedService.errorShowMessage.next(true);
          this.sharedService.hideErrorMessage();
        } else {
          this.sharedService.stringErrorMessage.next("server error, try later !");
          this.sharedService.errorShowMessage.next(true);
          this.sharedService.hideErrorMessage();
        }
      }
    );


  }

}
