import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { TranslationService } from 'src/app/shared/translation.service';
import { AuthenticationService } from '../Services/authentication.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  constructor(public translate: TranslationService,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private sharedService: SharedService,
     private router: Router) { }

  @ViewChild('forgetForm', {static: true}) forgetForm: NgForm;
  numberPhone: string;
  invalidPhone: boolean;

  ngOnInit(): void {
  }

  //has error
  hasError(e: any) {
    console.log(e);
    this.invalidPhone = e;
  }

  //on country change
  onCountryChange(e: any) {
    console.log(e);
  }

  //getNumber($event)
  getNumber(event: any) {
    console.log(event);
    this.numberPhone = event;
    console.log(this.numberPhone);
  }

  //telInputObject
  telInputObject(e: any) {
    console.log(e);
  }


  onSubmitted() {
    console.log(this.forgetForm.value);
    this.authService.forgetPassword(this.forgetForm.value).subscribe(
      (response: any) => {
        console.log('USER ID => ', response.data);
        this.router.navigate(['../verfication-code/' + response.data + '/' + 'forgetPass'], {relativeTo: this.route});
      },
      (error) => {
        console.log(error);
        if(error.error) {
          this.sharedService.stringErrorMessage.next(error.error.error);
          this.sharedService.errorShowMessage.next(true);
          this.sharedService.hideErrorMessage();
        } else {
          this.sharedService.stringErrorMessage.next("server error, try later !");
          this.sharedService.errorShowMessage.next(true);
          this.sharedService.hideErrorMessage();
        }
      }
    )
  }

}
