import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesService } from 'src/app/pages/pages.service';
import { TranslationService } from 'src/app/shared/translation.service';
import { SharedService } from '../../shared/shared.service';
import { AuthenticationService } from '../Services/authentication.service';
import { SocialAuthService, GoogleLoginProvider, FacebookLoginProvider, SocialUser } from 'angularx-social-login';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(public translate: TranslationService,
      private router: Router,
      private route: ActivatedRoute,
      private sharedService: SharedService,
      private pagesService: PagesService,
      private socialAuthService: SocialAuthService,
      private authService: AuthenticationService) { }

  @ViewChild('registerForm', {static: true}) registerForm: NgForm;

  //countries data
  countries: any[];

  //cities data
  cities: any[];
  selectedCity: number;
  codePhoneNumber: string;
  checkCodePhone: boolean = true;
  socialUser: SocialUser;


  ngOnInit(): void {

    //get social media data from  route data
    this.route.queryParams.subscribe(
      (userData: any) => {
        console.log('user dattaaaa', userData);
        this.socialUser = JSON.parse(userData['data']);
        if(userData.id) {
          console.log('social medi object ==> ', this.socialUser);
          this.registerForm.value.email = this.socialUser.email;
          this.registerForm.value.name = this.socialUser.name;
        }
      }
    );


    //get countries
    this.pagesService.getCountry().subscribe(
      (response: any) => {
        console.log('countries list ===> ', response);
        this.countries = response.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }


  //change country
  handelChangeCountry(event: any) {
    console.log(event.target.value);
    this.getCities(event.target.value);
  }

  //handelChangeCity
  handelChangeCity(city: any) {
    console.log(city.target.value);
    this.selectedCity = city.target.value;
  }

  //getCities
  getCities(countryId: number) {
    this.pagesService.getCity(countryId).subscribe(
      (response: any) => {
        this.cities = response.data;
      },
      (error) => {
        console.log(error);
      }
    )
  }


  //on country change
  onCountryChange(e: any) {
    console.log(e);
  }

  //getNumber($event)
  getNumber(event: any) {
    console.log(event);
    this.codePhoneNumber = event;
  }

  //telInputObject
  telInputObject(e: any) {
    console.log(e);
  }

   //has error
   hasError(e: any) {
    console.log(e);
    this.checkCodePhone = e;
    if(!e) {
      this.sharedService.stringErrorMessage.next("رقم الهاتف الذي ادخلته غير مطابق كود الدوله");
      this.sharedService.errorShowMessage.next(true);
      this.sharedService.hideErrorMessage();
    }
  }

  //submitted form
  submittedForm() {
    //city id validate
    if(this.selectedCity) {
      this.registerForm.value.cityId = +this.selectedCity;
    } else {
      this.sharedService.stringErrorMessage.next("please select country and choose city !");
      this.sharedService.errorShowMessage.next(true);
      this.sharedService.hideErrorMessage();
      return;
    }


    if(this.codePhoneNumber) {
      this.registerForm.value.phoneNumber = this.codePhoneNumber;
    }

    if(this.socialUser?.id) {
      this.registerForm.value.socialId = this.socialUser.id;
    }

    //validate password to equal confrm password
    if(this.registerForm.value.password != this.registerForm.value.confirmPassword) {
      this.sharedService.stringErrorMessage.next("password not equal confirm password !");
      this.sharedService.errorShowMessage.next(true);
      this.sharedService.hideErrorMessage();
    } else {

      console.log(this.registerForm.value);
      this.register();
    }

  };


  //register
  register(){
    if(this.socialUser?.id){
      this.registerForm.value.socialId = String(this.socialUser?.id);
    }
    // this.registerForm.value.socialId = "string";
    console.log("this.registerForm.value",this.registerForm.value);

    this.authService.register(this.registerForm.value).subscribe(
      (response: any) => {
        console.log(response);
        this.authService.setUser(response.data);
        if(this.socialUser?.id) {
          // this.router.navigate(['../verfication-code/'], {relativeTo: this.route, queryParams: {id: this.socialUser.id, email: this.socialUser.email}});
          // this.router.navigate(['../verfication-code/'+ this.socialUser.id + '/register'], {relativeTo: this.route, queryParams: {id: this.socialUser.id, email: this.socialUser.email}});
          this.router.navigate(['../verfication-code/' + response.data.userId  + '/register'], {relativeTo: this.route});
        } else {
          this.router.navigate(['../verfication-code/'  + response.data.userId + '/register'], {relativeTo: this.route});
        }
      },
      (error) => {
        if(error.error) {
          console.log(error.error.error);
          this.sharedService.stringErrorMessage.next(error.error.error);
          this.sharedService.errorShowMessage.next(true);
          this.sharedService.hideErrorMessage();
        } else {
          this.sharedService.stringErrorMessage.next("server error, try later !");
          this.sharedService.errorShowMessage.next(true);
          this.sharedService.hideErrorMessage();
        }
      }
    );
  };

  //login social
  loginSocial(type: string) {
    switch (type) {
      case 'google' :
        this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
      break;

      case 'facebook' :
        this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
        break;
    }
  }


}
