<div class="auth">
    <div class="container">
        <div class="row">
          <!--   <div class="col-12 col-md-6">
                <div class="d-none d-md-block">
                    <app-logo-auth></app-logo-auth>
                </div>
            </div> -->
            <div class="col-12">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>

